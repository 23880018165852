import React from 'react'
import './style.scss'

import Contact from '../Header/Contact'
import SocialIcons from '../SocialIcons'

function Footer(props) {

  return (
		<footer className='master-footer flex-12 c5'>
			<div className="span-6 span-12-mobile contact-container">
				<div className="contact-info">
					<Contact/>
				</div>
			</div>
			<div className="span-6 span-12-mobile social-icons-container">
				<SocialIcons/>
			</div>
      <div className="span-6 span-12-mobile container-copy">
          <a className="underline join" href="#">Join our mailing list</a>
					<div className="bottom-left">&copy; 2021 SOLARMY LLC.</div>	
			</div>
			<div className="span-6 span-12-mobile logo-container">
				<div className="logo">	
				</div>
			</div>

		</footer>
  )
}

export default Footer